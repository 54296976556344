import * as Actions from "../constants";
import { getASOData, getBanner } from "../service";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { getCodeLanguage } from "@store/common/selectors";

function* getDataASO() {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getASOData, codeLanguage);
    const { data } = res;
    if (res.retCode === 0) {
      yield put({ type: Actions.GET_DATA_ASO_SUCCESS, payload: data });
    } else {
      yield put({ type: Actions.GET_DATA_ASO_FAILED, error: res.retText });
    }
  } catch (e) {
    yield put({ type: Actions.GET_DATA_ASO_FAILED, error: e });
  }
}

function* getDataASOBanner({ payload }) {
  try {
    const res = yield call(getBanner, payload);
    const { data } = res;
    if (res.retCode === 0) {
      yield put({ type: Actions.GET_ASO_BANNER_SUCCESS, payload: data });
    } else {
      yield put({ type: Actions.GET_ASO_BANNER_FAILED, error: res.retText });
    }
  } catch (e) {
    yield put({ type: Actions.GET_ASO_BANNER_FAILED, error: e });
  }
}

export default function* ASOSaga() {
  yield takeEvery(Actions.GET_DATA_ASO_REQUEST, getDataASO);
  yield takeEvery(Actions.GET_ASO_BANNER_REQUEST, getDataASOBanner);
}
