import API from '@Modules/Support/configs/api';
import apiMethod from '@utility/ApiMethod';

export const getSupportGroupList = async ({
  codeLanguage = 'vi-VN',
  payload,
}) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.SUPPORT_GROUP_LIST}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSupportList = async ({ codeLanguage = 'vi-VN', payload }) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.SUPPORT_LIST}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSupportSearchList = async ({
  codeLanguage = 'vi-VN',
  payload,
}) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.SUPPORT_SEARCH_LIST}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSupportDetail = async ({ codeLanguage = 'vi-VN', payload }) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.SUPPORT_DETAIL}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const submitFeedbackform = async (payload, codeLanguage) => {
  try {
    const { data } = await apiMethod.post(
      `${codeLanguage}/${API.FEEDBACK}`,
      payload
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
