import intlMessagesEN from "./i18n/localization/en.json"
import intlMessagesVi from "./i18n/localization/vi.json"
import initReducer from "./Store/initReducer"
import initSagas from "./Store/initSagas"

export default {
  name: "Teacher",
  dir: "Teacher",
  pathRoot: "teachers",
  routes: [
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true
      },
      props: {
        title: "Đội ngũ giáo viên | 5 Phút Thuộc Bài",
        titleI18n: "Teacher:title",
        exact: true
      }
    },
    {
      url: "search",
      component: "Page/search",
      meta: {
        authRoute: true
      },
      props: {
        title: "Đội ngũ giáo viên | 5 Phút Thuộc Bài",
        titleI18n: "Teacher:title",
        exact: true
      }
    }
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  redux: initReducer,
  sagas: initSagas
}
