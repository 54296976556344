import * as Actions from '../constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { PAGE_DEFAULT, LIMIT_DEFAULT } from '@configs/contants';

const initState = {
  levelList: [],
  teacherListByLevel: [],
  teacherListSearch: [],
  levelTabListTeacherByLevel: [],
  levelTabListTeacherSearch: [],
  pageIndexTeacherByLevel: PAGE_DEFAULT,
  pageSizeTeacherByLevel: LIMIT_DEFAULT,
  totalRowsTeacherByLevel: 0,
  totalPageTeacherByLevel: 0,
  pageIndexTeacherSearch: PAGE_DEFAULT,
  pageSizeTeacherSearch: LIMIT_DEFAULT,
  totalRowsTeacherSearch: 0,
  totalPageTeacherSearch: 0,
  loadingTeacherByLevel: false,
  loadingTeacherSearch: false,
  loadingLevelList: false,
  levelCodeTeacherByLevel: undefined,
  levelCodeTeacherSearch: undefined,
  error: '',
};

const TeacherReducers = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_LEVEL_LIST_REQUEST:
      return { ...state, loadingLevelList: true };
    case Actions.GET_LEVEL_LIST_SUCCESS:
      return {
        ...state,
        levelList: action.payload,
        loadingLevelList: false,
      };
    case Actions.GET_LEVEL_LIST_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingLevelList: false,
      };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_REQUEST:
      return { ...state, loadingTeacherByLevel: true };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_SUCCESS:
      return {
        ...state,
        levelTabListTeacherByLevel: action.payload.levelTabList,
        teacherListByLevel: action.payload.teacherList,
        loadingTeacherByLevel: false,
        pageIndexTeacherByLevel: action.payload.paging.curPage,
        pageSizeTeacherByLevel: action.payload.paging.perPage,
        totalRowsTeacherByLevel: action.payload.paging.totalRows,
        totalPageTeacherByLevel: action.payload.paging.totalPage,
        levelCodeTeacherByLevel: action.payload.levelCode,
      };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingTeacherByLevel: false,
      };
    case Actions.GET_TEACHER_LIST_SEARCH_REQUEST:
      return { ...state, loadingTeacherSearch: true };
    case Actions.GET_TEACHER_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        levelTabListTeacherSearch: action.payload.levelTabList,
        teacherListSearch: action.payload.teacherList,
        loadingTeacherSearch: false,
        pageIndexTeacherSearch: action.payload.paging?.curPage,
        pageSizeTeacherSearch: action.payload.paging?.perPage,
        totalRowsTeacherSearch: action.payload.paging?.totalRows,
        totalPageTeacherSearch: action.payload.paging?.totalPage,
        levelCodeTeacherSearch: action.payload.levelCode,
      };
    case Actions.GET_TEACHER_LIST_SEARCH_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingTeacherSearch: false,
      };

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'Teacher',
  storage,
  blacklist: ['loading', 'error'],
};

export default persistReducer(persistConfig, TeacherReducers);
