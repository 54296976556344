import * as Actions from './constants'
import { getCourseInfo } from './service'
import { put, call, takeEvery, select } from "redux-saga/effects"
import { getCodeLanguage } from "@store/common/selectors"
import { RETCODE_SUCCESS, SUCCESS } from "@configs/contants"

export const setActiveId = (payload) => {
  return {
    type: Actions.SET_ACTIVE_ID,
    payload
  }
}

function* fetchCourseInfo({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage)
    const res = yield call(getCourseInfo, {
      payload,
      codeLanguage
    })
    const { data } = res
    if (res.status === SUCCESS && data.retCode === RETCODE_SUCCESS) {
      yield put({ type: Actions.GET_INVOICE_INFO_SUCCESS, payload: data.data })
    } else {
      yield put({ type: Actions.GET_INVOICE_INFO_ERROR, error: data.retText })
    }
  } catch (e) {
    yield put({ type: Actions.GET_INVOICE_INFO_ERROR, error: e })
  }
}


export default function* paymentSaga() {
  yield takeEvery(Actions.GET_INVOICE_INFO, fetchCourseInfo)
}