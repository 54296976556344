import {handleActions} from 'redux-actions'
import {
    generateFormSubmitRedux
} from '@utility/StoreMethod'
import * as Actions from './constants'

const name = "formPayment"
const reduxFormGenerate = generateFormSubmitRedux(name)

export const types = {
    ...reduxFormGenerate.types
}

export const actions = {
    ...reduxFormGenerate.actions,
    formPaymentFnUpdate: (payload) => {
        return {
            type: Actions.INVOICE_UPDATE,
            payload
          }
    }
}

const defaultState = {
    ...reduxFormGenerate.defaultState
}

export default handleActions(
    {
        ...reduxFormGenerate.handleActions
    },
    defaultState
)
