import intlMessagesEN from "./i18n/localization/en.json"
import intlMessagesVi from "./i18n/localization/vi.json"

import agencyReducer from "./Store/Agency/reducer"
import agancySagas from "./Store/Agency/sagas"

import agencySearchReducer from "./Store/AgencySearch/reducer"
import agancySearchSagas from "./Store/AgencySearch/sagas"

import formReducer from "./Store/FormPartner/reducer"
import formSagas from "./Store/FormPartner/sagas"

export default {
  name: "Partner",
  dir: "Partner",
  pathRoot: "partners",
  routes: [
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true
      },
      props: {
        title: "Đối tác | 5 Phút Thuộc Bài",
        titleI18n: "Partner:title",
        exact: true
      }
    },
    {
      url: "search",
      component: "Page/search",
      meta: {
        authRoute: true
      },
      props: {
        title: "Đối tác | 5 Phút Thuộc Bài",
        titleI18n: "Partner:title",
        exact: true
      }
    }
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  sagas: [agancySagas(), agancySearchSagas(), formSagas()],
  redux: {
    Agency: agencyReducer,
    AgencySearch: agencySearchReducer,
    formPartner: formReducer
  }
  // redux: initReducer,
}
