import API from '@Modules/Teacher/configs/api';
import apiMethod from '@utility/ApiMethod';

export const getLevelList = async ({ codeLanguage = 'vi-VN', payload }) => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.LEVEL_LIST}`, {
      params: {
        ...payload,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTeacherListByLevel = async ({
  codeLanguage = 'vi-VN',
  payload,
}) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.TEACHER_LIST_BY_LEVEL}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTeacherListBySearch = async ({
  codeLanguage = 'vi-VN',
  payload,
}) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.TEACHER_LIST_BY_SEARCH}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
