import { handleActions } from "redux-actions"
import { generateFormSubmitRedux } from "@utility/StoreMethod"
import * as Actions from "./constants"

const name = "formPartner"
const reduxFormGenerate = generateFormSubmitRedux(name)

export const types = {
  ...reduxFormGenerate.types
}

export const actions = {
  ...reduxFormGenerate.actions
}

const defaultState = {
  ...reduxFormGenerate.defaultState
}

export default handleActions(
  {
    ...reduxFormGenerate.handleActions
  },
  defaultState
)
