import API from "../../configs/api"
import apiMethod from "../../../../utility/ApiMethod"

export const formPartnerCallMethod = ({ payload, codeLanguage }) => {
  return apiMethod.post(codeLanguage + API.POST_CREATE_PARTNER, {
    genderCode: payload?.gender,
    fullName: payload?.fullname,
    email: payload?.email,
    phoneNo: payload?.phone,
    packedCode: payload?.pack,
    districtId: payload?.district * 1,
    cityId: payload?.city * 1,
    description: payload?.content,
    remark: ""
  })
}
export const getUserCallName = ({ payload, codeLanguage }) => {
  return apiMethod.get("vi-VI/Option​ABCCCC/GetList", {
    params: {
      optionCode: "DANHXUNG"
    }
  })
}
export const getPackageList = ({ payload, codeLanguage }) => {
  return apiMethod.get(codeLanguage + "/Option​/GetList", {
    params: {
      optionCode: "AGENCYGROUP"
    }
  })
}
