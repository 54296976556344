import API from "@Modules/ASO/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getASOData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getBanner = async (payload) => {
  try {
    const { data } = await apiMethod.get(`${API.GET_ASO_BANNER}`, {
      params: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
