import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';
import initReducer from './Store/initReducer';
import initSagas from './Store/initSagas';

export default {
  name: 'Support',
  dir: 'Support',
  pathRoot: 'support',
  routes: [
    {
      url: 'detail',
      component: 'Page/Detail',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Hỗ trợ | 5 Phút Thuộc Bài',
        titleI18n: 'Support:title',
      },
    },
    {
      url: 'search',
      component: 'Page/Search',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Hỗ trợ | 5 Phút Thuộc Bài',
        titleI18n: 'Support:title',
      },
    },
    {
      url: ':id',
      component: 'Page/SupportList',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Hỗ trợ | 5 Phút Thuộc Bài',
        titleI18n: 'Support:title',
      },
    },
    {
      url: '',
      component: 'Page/index',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Hỗ trợ | 5 Phút Thuộc Bài',
        titleI18n: 'Support:title',
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: 'Any',
  redux: initReducer,
  sagas: initSagas,
};
