import * as Actions from "../constants";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initState = {
  ASOData: {},
  bannerData: [],
  loading: false,
  error: "",
};

const defaultBanner = [
  {
    id: 1,
    image_desktop: require("@Modules/ASO/assets/images/default-banner.png")
      .default,
    image_tablet:
      require("@Modules/ASO/assets/images/default-banner-tablet.png").default,
    image_mobile:
      require("@Modules/ASO/assets/images/default-banner-mobile.png").default,
    url_link: "#",
  },
];

const ASOReducers = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_DATA_ASO_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_DATA_ASO_SUCCESS:
      return {
        ...state,
        ASOData: action.payload,
        loading: false,
      };

    case Actions.GET_DATA_ASO_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case Actions.GET_ASO_BANNER_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_ASO_BANNER_SUCCESS:
      return {
        ...state,
        bannerData: action.payload.length > 0 ? action.payload : defaultBanner,
        loading: false,
      };
    case Actions.GET_ASO_BANNER_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: "ASO",
  storage,
  blacklist: ["loading", "error"],
};

export default persistReducer(persistConfig, ASOReducers);
