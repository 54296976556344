import * as Actions from './constants'
import storage from "redux-persist/lib/storage"
import {persistReducer} from 'redux-persist'

const initState = {
  activeId: '',
  courseInfo: {},
  error: ''
}

const PaymentReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.SET_ACTIVE_ID:
      return {...state, activeId: action.payload}

    case Actions.GET_INVOICE_INFO:
      return {...state, loading: true}
    case Actions.GET_INVOICE_INFO_SUCCESS: 
      return {
        ...state,
        courseInfo: action.payload,
        loading: false,
      }
    case Actions.GET_INVOICE_INFO_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case Actions.GET_INVOICE_INFO_RESET:
      return {
        ...state,
        loading: false,
        error: ''
      }
    case Actions.RESET_INVOICE_INFO: 
      return initState
    default: {
      return state
    }
  }
}


const persistConfig = {
  key: 'Payment',
  storage,
  blacklist: ['loading', 'error']
}

export default persistReducer(persistConfig, PaymentReducer)