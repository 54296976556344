import * as Actions from '../constants';
import {
  getSupportGroupList,
  getSupportList,
  getSupportSearchList,
  getSupportDetail,
} from '../service';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getCodeLanguage } from '@store/common/selectors';

function* getSupportGroupListSaga() {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getSupportGroupList, { codeLanguage });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_SUPPORT_GROUP_LIST_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: Actions.GET_SUPPORT_GROUP_LIST_FAILED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_SUPPORT_GROUP_LIST_FAILED, error: e });
  }
}

function* getSupportListSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getSupportList, { codeLanguage, payload });
    const { data } = res;
    console.log('res', res);
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_SUPPORT_LIST_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: Actions.GET_SUPPORT_LIST_FAILED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_SUPPORT_LIST_FAILED, error: e });
  }
}

function* getSupportListByKeySaga({ payload: { key, body } }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getSupportList, { codeLanguage, payload: body });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_SUPPORT_LIST_BY_KEY_SUCCESS,
        payload: { key, data },
      });
    } else {
      yield put({
        type: Actions.GET_SUPPORT_LIST_BY_KEY_FAILED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_SUPPORT_LIST_BY_KEY_FAILED, error: e });
  }
}

function* getSupportDetailSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getSupportDetail, { codeLanguage, payload });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_SUPPORT_DETAIL_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: Actions.GET_SUPPORT_DETAIL_FAILED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_SUPPORT_DETAIL_FAILED, error: e });
  }
}

function* getSupportSearchSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getSupportSearchList, { codeLanguage, payload });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_SUPPORT_SEARCH_LIST_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: Actions.GET_SUPPORT_SEARCH_LIST_FAILED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_SUPPORT_SEARCH_LIST_FAILED, error: e });
  }
}

export default function* supportSaga() {
  yield takeEvery(
    Actions.GET_SUPPORT_GROUP_LIST_REQUEST,
    getSupportGroupListSaga
  );
  yield takeEvery(Actions.GET_SUPPORT_LIST_REQUEST, getSupportListSaga);
  yield takeEvery(
    Actions.GET_SUPPORT_LIST_BY_KEY_REQUEST,
    getSupportListByKeySaga
  );
  yield takeEvery(Actions.GET_SUPPORT_DETAIL_REQUEST, getSupportDetailSaga);
  yield takeEvery(
    Actions.GET_SUPPORT_SEARCH_LIST_REQUEST,
    getSupportSearchSaga
  );
}
