export const GET_LEVEL_LIST_REQUEST = 'GET_LEVEL_LIST_REQUEST';
export const GET_LEVEL_LIST_SUCCESS = 'GET_LEVEL_LIST_SUCCESS';
export const GET_LEVEL_LIST_FAILDED = 'GET_LEVEL_LIST_FAILDED';

export const GET_TEACHER_LIST_BY_LEVEL_REQUEST =
  'GET_TEACHER_LIST_BY_LEVEL_REQUEST';
export const GET_TEACHER_LIST_BY_LEVEL_SUCCESS =
  'GET_TEACHER_LIST_BY_LEVEL_SUCCESS';
export const GET_TEACHER_LIST_BY_LEVEL_FAILDED =
  'GET_TEACHER_LIST_BY_LEVEL_FAILDED';

export const GET_TEACHER_LIST_SEARCH_REQUEST =
  'GET_TEACHER_LIST_SEARCH_REQUEST';
export const GET_TEACHER_LIST_SEARCH_SUCCESS =
  'GET_TEACHER_LIST_SEARCH_SUCCESS';
export const GET_TEACHER_LIST_SEARCH_FAILDED =
  'GET_TEACHER_LIST_SEARCH_FAILDED';
