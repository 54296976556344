export const GET_SUPPORT_GROUP_LIST_REQUEST = 'GET_SUPPORT_GROUP_LIST_REQUEST';
export const GET_SUPPORT_GROUP_LIST_SUCCESS = 'GET_SUPPORT_GROUP_LIST_SUCCESS';
export const GET_SUPPORT_GROUP_LIST_FAILED = 'GET_SUPPORT_GROUP_LIST_FAILED';

export const GET_SUPPORT_LIST_BY_KEY_REQUEST =
  'GET_SUPPORT_LIST_BY_KEY_REQUEST';
export const GET_SUPPORT_LIST_BY_KEY_SUCCESS =
  'GET_SUPPORT_LIST_BY_KEY_SUCCESS';
export const GET_SUPPORT_LIST_BY_KEY_FAILED = 'GET_SUPPORT_LIST_BY_KEY_FAILED';

export const GET_SUPPORT_LIST_REQUEST = 'GET_SUPPORT_LIST_REQUEST';
export const GET_SUPPORT_LIST_SUCCESS = 'GET_SUPPORT_LIST_SUCCESS';
export const GET_SUPPORT_LIST_FAILED = 'GET_SUPPORT_LIST_FAILED';

export const GET_SUPPORT_SEARCH_LIST_REQUEST =
  'GET_SUPPORT_SEARCH_LIST_REQUEST';
export const GET_SUPPORT_SEARCH_LIST_SUCCESS =
  'GET_SUPPORT_SEARCH_LIST_SUCCESS';
export const GET_SUPPORT_SEARCH_LIST_FAILED = 'GET_SUPPORT_SEARCH_LIST_FAILED';

export const GET_SUPPORT_DETAIL_REQUEST = 'GET_SUPPORT_DETAIL_REQUEST';
export const GET_SUPPORT_DETAIL_SUCCESS = 'GET_SUPPORT_DETAIL_SUCCESS';
export const GET_SUPPORT_DETAIL_FAILED = 'GET_SUPPORT_DETAIL_FAILED';
