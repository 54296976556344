import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';
// import initReducer from './Store/initReducer';

export default {
  name: 'Helper',
  dir: 'Helper',
  pathRoot: 'help',
  routes: [
    {
      url: 'license',
      component: 'Page/License',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Giấy phép đào tạo | 5 Phút Thuộc Bài',
        titleI18n: 'Helper:titleLicense',
      },
    },
    {
      url: 'payment-term',
      component: 'Page/Payment',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Điều khoản thanh toán | 5 Phút Thuộc Bài',
        titleI18n: 'Helper:titlePayment',
      },
    },
    {
      url: 'buy-guide',
      component: 'Page/BuyGuide',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Hướng dẫn mua hàng | 5 Phút Thuộc Bài',
        titleI18n: 'Helper:titleBuy',
      },
    },

    {
      url: 'private-policy',
      component: 'Page/Private',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Chính sách bảo mật | 5 Phút Thuộc Bài',
        titleI18n: 'Helper:titlePrivate',
      },
    },
    {
      url: 'complain-policy',
      component: 'Page/Complain',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Chính sách khiếu nại | 5 Phút Thuộc Bài',
        titleI18n: 'Helper:titleComplain',
      },
    },
    {
      url: 'return-policy',
      component: 'Page/Return',
      meta: {
        authRoute: true,
      },
      props: {
        title: 'Chính sách hoàn trả | 5 Phút Thuộc Bài',
        titleI18n: 'Helper:titleReturn',
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: 'Any',
  // redux: initReducer,
};
