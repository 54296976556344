import * as Actions from '../constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const initState = {
  supportGroupList: [],
  supportSearchList: [],
  loadingSupportGroup: false,
  loadingSupportByKey: false,
  loadingSupport: false,
  loadingSupportDetail: false,
  loadingSupportSearch: false,
  error: '',
  supportListByKey: {},
  supportList: [],
  supportDetail: {},
  pagingSupportSearch: {},
  pagingSupportList: {},
};

const SupportReducers = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_SUPPORT_GROUP_LIST_REQUEST:
      return { ...state, loadingSupportGroup: true };
    case Actions.GET_SUPPORT_GROUP_LIST_SUCCESS:
      return {
        ...state,
        supportGroupList: action.payload,
        loadingSupportGroup: false,
      };
    case Actions.GET_SUPPORT_GROUP_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        loadingSupportGroup: false,
      };

    case Actions.GET_SUPPORT_LIST_REQUEST:
      return { ...state, loadingSupport: true };
    case Actions.GET_SUPPORT_LIST_SUCCESS:
      return {
        ...state,
        supportList: action.payload?.supportList,
        pagingSupportList: action.payload?.paging,
        loadingSupport: false,
      };
    case Actions.GET_SUPPORT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        loadingSupport: false,
      };

    case Actions.GET_SUPPORT_LIST_BY_KEY_REQUEST:
      return { ...state, loadingSupportByKey: true };
    case Actions.GET_SUPPORT_LIST_BY_KEY_SUCCESS:
      const {
        key,
        data: { paging = {}, supportList = [] },
      } = action.payload;
      return {
        ...state,
        supportListByKey: { ...state.supportList, [key]: supportList },
        loadingSupportByKey: false,
      };
    case Actions.GET_SUPPORT_LIST_BY_KEY_FAILED:
      return {
        ...state,
        error: action.error,
        loadingSupportByKey: false,
      };

    case Actions.GET_SUPPORT_DETAIL_REQUEST:
      return { ...state, loadingSupportDetail: true };
    case Actions.GET_SUPPORT_DETAIL_SUCCESS:
      return {
        ...state,
        supportDetail: action.payload,
        loadingSupportDetail: false,
      };
    case Actions.GET_SUPPORT_DETAIL_FAILED:
      return {
        ...state,
        error: action.error,
        supportDetail: false,
      };

    case Actions.GET_SUPPORT_SEARCH_LIST_REQUEST:
      return { ...state, loadingSupportSearch: true };
    case Actions.GET_SUPPORT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        supportSearchList: action.payload.supportList,
        pagingSupportSearch: action.payload.paging,
        loadingSupportSearch: false,
      };
    case Actions.GET_SUPPORT_SEARCH_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        loadingSupportSearch: false,
      };

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'Support',
  storage,
  blacklist: ['loading', 'error'],
};

export default persistReducer(persistConfig, SupportReducers);
