import API from "../../configs/api";
import apiMethod from "@utility/ApiMethodV2";

export const getCourseInfo = ({ payload, codeLanguage }) => {
  return apiMethod.post(codeLanguage + API.INVOICE_INFO, {
    userId: payload.userId,
    itemCode: payload.itemCode,
  });
};

export const getGroupCode = (payload) => {
  return apiMethod.get(`${API.GET_LIST_GROUPCODE}/${payload.groupCode}`);
};

export const responseNganLuong = async ({
  headers,
  codeLanguage = "vi-VN",
}) => {
  try {
    return await apiMethod.get(
      "/" + codeLanguage + API.GET_NGANLUONG_RESPONSE + headers + "&client=true"
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateInvoice = async ({ body, codeLanguage }) => {
  try {
    const { data } = await apiMethod.post(
      codeLanguage + API.UPDATE_INVOICE,
      body
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListBankNL = ({ codeLanguage, payload }) => {
  const { params, ...res } = payload;

  return apiMethod.post(API.GET_LIST_BANK_NL, res, { params });
};
